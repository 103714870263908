import React, { useEffect, useState } from "react";
import $ from "jquery";
import "bootstrap";
import  MortgageCard from "../../components/adviser/MortgageCard";
import MortgageCardDetails from "../../components/adviser/MortgageCardDetails";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import {
  getAdvisorAcceptedLeads,
  getUserAdviceNotes,
  startChat,
  getCountAcceptedFilter,
} from "../../components/common/service";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import { ADVISER_LEADS_ACCEPTED, ADVISER_LEADS_ACCEPTED_FILTER, NO_DATA_FOUND } from "../../Constants";
import { Link } from "react-router-dom";

let initialState = {
  status: ['accepted'],
};
let state = {
  cart: [],
};
const AdviserLeadsAccepted = (props: any) => {
  const user = useLoggedInUserData();
  const [loadedCount, setLoadedCount] = useState<number>(20);
  const [leads, setLeads] = useState<object[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedLead, setSelectedLead] = useState<any>({});
  const [userNotes, setUserNotes] = useState<object[]>([]);
  const [formState, setFormState] = useState(initialState);
  const [countData, setCountData] = useState<any>({});
  const [statusName, setstatusName] = useState('accepted');
  const [selectedDuration, setSelectedDuration] = useState('this_month');

  const queryParams = new URLSearchParams(window.location.search);
  const accepted_id = queryParams.get("accepted_id");
  const time = queryParams.get("time");
  const lead = queryParams.get("lead");
  const [names, setNames] = useState<any>([]);
  let arr:any = [];
  var statusArr:any = ['accepted'];
  // let statusName = "accepted";

  useEffect(() => {
    if (props.location.state && props.location.state?.filteredLeads) {
      if (!isLoading) {
        setLeads(props.location.state.filteredLeads);
        setSelectedLead(props.location.state.filteredLeads[0]);
      }
    }
    getCount();
  }, [props.location.state]);
  useEffect(() => {
    console.log('Selected value:', selectedDuration);
    getAdvisorAcceptedLeadsCall();
    getCount();
    // You can perform additional actions or updates here
  }, [selectedDuration]);

  useEffect(() => {
    if (
      !props.location.state?.filteredLeads ||
      props.location.state?.filteredLeads.lengh === 0
    ) {
      getAdvisorAcceptedLeadsCall();
    }
    if(time!=null && lead!=null){
      setFormState({
        status: [],
      });
      if(lead=='live_leads'){
        setstatusName('accepted');
      }else{
        setstatusName(lead);      
      }
    }else{
      setFormState(initialState);   
    }
  }, []);

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
      const selectedValue = event.target.value;
      statusArr = [selectedValue];
      setstatusName(selectedValue);
      // statusName = selectedValue;
      getAdvisorAcceptedLeadsCall();
    
  };

  const getCount = async () => {
    setIsLoading(true);
    const res = await getCountAcceptedFilter('duration',selectedDuration);
    if (res?.success || res.status) {
      setCountData(res.data);
    }
    setIsLoading(false);
  };

  const getAdvisorAcceptedLeadsCall = async () => {
    setIsLoading(true);
    let res: any;
    if(lead!=undefined && time!=undefined){
      res = await getAdvisorAcceptedLeads('lead',lead,'time',time,'status',statusArr);
    }else{

      res = await getAdvisorAcceptedLeads('','','time',selectedDuration,'status',statusArr);
    }
    if (res?.success || res?.status) {
      setLeads(res.data);
      setIsLoading(false);
      if (res.data.length) {
        let selData = res.data[0];
        if (accepted_id) {
          const onlysingleLead = res.data.filter(
            (lead: any) => lead.id == accepted_id
          );
          setLeads(onlysingleLead);
          setSelectedLead(res.data[0]);
          selData = onlysingleLead[0] || {};
          setIsLoading(false);
        }

        const messageCount = await handleMessageCount(
          selData.user_id,
          user.id,
          selData.id
        );
        getUserAdviceNotes(selData.id,user.id).then((res) => {
          if(res.success || res.status) {
            setUserNotes(res.data);
          }
        });
        setSelectedLead({ ...selData, messageCount });
      }
    }
    setIsLoading(false);
  };

  const getUserNotesCall = async (advice_id: number) => {
    setIsLoading(true);
    const res = await getUserAdviceNotes(advice_id,user.id);
    if (res?.success || res?.status) {
      setUserNotes(res.data);
    }
    setIsLoading(false);
  };

  const handleItemClick = (value:string) => {
    setSelectedDuration(value);
    console.log('Selected value:', selectedDuration);
    // getAdvisorAcceptedLeadsCall();
    // const redirectUrl = `${ADVISER_LEADS_ACCEPTED}?lead=${statusName}&time=${value}`;

    // Redirect the user to the specified URL
    // window.location.href = redirectUrl;
    // You can perform additional actions or updates here
  };

  const handleMessageCount = async (
    from_user_id: number,
    to_user_id: number,
    advicearea_id: number
  ) => {
    if (from_user_id && to_user_id && advicearea_id) {
      const res = await startChat({
        from_user_id,
        to_user_id,
        advicearea_id: advicearea_id,
        type: 1,
      });
      return res.data || 0;
    } else {
      return 0;
    }
  };

  return (
    <>
      <main className="dashboard">
        <section className="page-title leadmatch">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec advice-area-sec profile-sec advice-lead-matches advice-matches-datas new-advice-matches pb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="recent-box">
                  <div className="row align-items-center justify-content-center mb-4">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-xl-4 col-lg-5 col-md-12 mb-4 pr-xl-3 pl-xl-3 left-right-acc">
                          <div id="accordion" className="accordion">
                            <div className="address-acc">
                              <div
                                className="cards collapsed"
                                data-toggle="collapse"
                                aria-expanded="true"
                              >
                                <h6>Lead Summary</h6>
                              </div>
                              <div
                                id="left_side"
                                className="collapse show mt-lg-0 mt-3"
                                data-parent="#accordion"
                              >
                                <div className="row align-items-center tabss">
                                    <div className="col-md-12 col-12">
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed">
                                            <b><p>Purchased leads</p></b>
                                          </div>
                                          <div
                                            
                                            className="directory-section"
                                          >
                                            <div className="answers">
                                              <div className="row ">
                                                <div className="times answers-row">
                                                  <ul className="pl-0 ml-0 text-center nav nav-pills">
                                                    <li className="nav-item">
                                                      {time!='' && time=='this_month'?
                                                      <a
                                                        className="nav-link active"
                                                        data-toggle="pill"
                                                        href="#month"
                                                        onClick={() => handleItemClick('this_month')}
                                                      >
                                                        Month {time}
                                                      </a>:<a
                                                        className="nav-link active"
                                                        data-toggle="pill"
                                                        href="#month"
                                                        onClick={() => handleItemClick('this_month')}
                                                      >
                                                        Month
                                                      </a>}
                                                    </li>
                                                    <li className="nav-item">
                                                    {time!='' && time=='quarter'?
                                                      <a
                                                        className="nav-link active"
                                                        data-toggle="pill"
                                                        href="#quarter"
                                                        onClick={() => handleItemClick('quarter')}
                                                      >
                                                        Quarter
                                                      </a>:<a
                                                        className="nav-link"
                                                        data-toggle="pill"
                                                        href="#quarter"
                                                        onClick={() => handleItemClick('quarter')}
                                                      >
                                                        Quarter
                                                      </a>}
                                                    </li>
                                                    <li className="nav-item">
                                                    {time!='' && time=='year'?
                                                      <a
                                                        className="nav-link active"
                                                        data-toggle="pill"
                                                        href="#year"
                                                        onClick={() => handleItemClick('year')}
                                                      >
                                                        Year
                                                      </a>:<a
                                                        className="nav-link"
                                                        data-toggle="pill"
                                                        href="#year"
                                                        onClick={() => handleItemClick('year')}
                                                      >
                                                        Year
                                                      </a>}
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div className="col-md-7 col-6">
                                                  <div className="form-group mb-0">
                                                    <input
                                                      type="radio"
                                                      id="accepted"
                                                      name="status"
                                                      className="status"
                                                      value={"accepted"}
                                                      checked={statusName=='accepted'}
                                                      onClick={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="accepted">
                                                    Prospect ({countData?.accepted})
                                                    </label>
                                                  </div>
                                                  <div className="form-group mb-0">
                                                    <input
                                                      type="radio"
                                                      id="completed"
                                                      name="status"
                                                      className="status"
                                                      value={"completed"}
                                                      checked={statusName=='completed'}
                                                      onClick={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="completed">
                                                      Completed ({countData?.completed})
                                                    </label>
                                                  </div>
                                                  <div className="form-group mb-0">
                                                    <input
                                                      type="radio"
                                                      id="no_response"
                                                      name="status"
                                                      className="status"
                                                      value={"no_response"}
                                                      checked={statusName=='no_response'}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="no_response">
                                                      No Response ({countData?.no_response})
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-md-5 col-6">
                                                  
                                                  <div className="form-group mb-0">
                                                    <input
                                                      type="radio"
                                                      id="hired"
                                                      name="status"
                                                      className="status"
                                                      value={"hired"}
                                                      checked={statusName=='hired'}
                                                      onClick={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="hired">
                                                      Hired ({countData?.hired})
                                                    </label>
                                                  </div>
                                                  
                                                  <div className="form-group mb-0">
                                                    <input
                                                      type="radio"
                                                      id="lost"
                                                      name="status"
                                                      className="status"
                                                      value={"lost"}
                                                      checked={statusName=='lost'}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="lost">
                                                      Lost ({countData?.lost})
                                                    </label>
                                                  </div>
                                                  
                                                </div>
                                              </div>
                                              {/* <div className="row" >
                                                      <button
                                                        type="button"
                                                        className="theme-btn"
                                                        onClick={
                                                          getAdvisorAcceptedLeadsCall
                                                        }
                                                      >
                                                        APPLY 
                                                      </button>
                                                  </div> */}
                                            </div>
                                          </div>
                                        </div>
                                  </div>
                                  {/* <div className="col-md-7 col-12">
                                    <span>
                                      {!accepted_id ? (
                                        <b>
                                          {leads.length ? leads.length : "No"}{" "}
                                          active leads
                                        </b>
                                      ) : (
                                        <b>active leads</b>
                                      )}
                                    </span>
                                  </div> */}
                                  {/* <div className="col-md-5 col-12 text-center">
                                    <Link
                                      to={ADVISER_LEADS_ACCEPTED_FILTER}
                                      className="btn-bg-blue filter-btn mt-md-0 mt-3 mb-0"
                                    >
                                      FILTER
                                    </Link>
                                  </div> */}
                                  <div className="col-12">
                                    <span className="border-bottom"></span>
                                  </div>
                                </div>
                                <div
                                  className={`left-sidebar ${
                                    leads.length > 2 && "left-sidebar-height"
                                  }`}
                                >
                                  <LoadingSpinner loading={isLoading} />

                                  <div className="lead-location">
                                    <ul className="nav nav-pills">
                                    {leads.length ? (
                                        leads.map((lead: any, index) => (
                                          index < loadedCount?
                                            <MortgageCard
                                              key={`${lead.id}-${index}`}
                                              {...lead}
                                              index={index}
                                              updateSelectedLead={async () => {
                                                const selData = lead;
                                                const messageCount =
                                                  await handleMessageCount(
                                                    selData.user_id,
                                                    user.id,
                                                    selData.id
                                                  );
                                                setSelectedLead({
                                                  ...selData,
                                                  messageCount: messageCount,
                                                });
                                              }}
                                              isAccepted
                                            />
                                              :null
                                          ))
                                          ) : !isLoading ? (
                                            <li className="col-md-12 pt-3 text-center">
                                              <div className="col-md-12 pt-3 text-center">
                                                <div className="view-btn text-center font-weight-bold">
                                                  {NO_DATA_FOUND}
                                                </div>
                                              </div>
                                            </li>
                                          ) : null}
                                      {/* {leads.map((lead: any, index) => {
                                        if (index < loadedCount) {
                                          return (
                                            <MortgageCard
                                              key={`${lead.id}-${index}`}
                                              {...lead}
                                              index={index}
                                              updateSelectedLead={async () => {
                                                const selData = lead;
                                                const messageCount =
                                                  await handleMessageCount(
                                                    selData.user_id,
                                                    user.id,
                                                    selData.id
                                                  );
                                                setSelectedLead({
                                                  ...selData,
                                                  messageCount: messageCount,
                                                });
                                              }}
                                              isAccepted
                                            />
                                          );
                                        }
                                      })} */}
                                    </ul>
                                  </div>

                                  <div
                                    style={{
                                      display:
                                        !isLoading && leads.length > loadedCount
                                          ? "block"
                                          : "none",
                                    }}
                                    className="row pt-2 mt-4"
                                  >
                                    <div className="col-12 text-center">
                                      <button
                                        className="btn-border-blue load-more"
                                        onClick={() =>
                                          setLoadedCount(
                                            (prevLoadedCount) =>
                                              prevLoadedCount + 5
                                          )
                                        }
                                      >
                                        LOAD MORE
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="profile-data col-xl-8 col-lg-7 col-md-12 left-right-acc">
                          <div id="accordion" className="accordion">
                            <div className="address-acc">
                              <div
                                className="cards collapsed"
                                data-toggle="collapse"
                                aria-expanded="true"
                              >
                                <h6>Lead Details</h6>
                              </div>
                              <div
                                id="right_side"
                                className="collapse show mt-lg-0 mt-3"
                                data-parent="#accordion"
                              >
                                <div className="tab-content">
                                  <div
                                    className="tab-pane fade show active"
                                    id="tab1"
                                    role="tabpanel"
                                    aria-labelledby="flamingo-tab"
                                  >
                                    {/* <MortgageCardDetails
                                      isAccepted
                                      {...selectedLead}
                                    /> */}
                                    {leads.length ? (
                                      <MortgageCardDetails  isAccepted
                                      {...selectedLead} />
                                    ) : isLoading ? (
                                      <LoadingSpinner loading={isLoading} />
                                    ) : (
                                      <div className="col-md-12 pt-3 text-center">
                                        <div className="view-btn text-center font-weight-bold">
                                          {NO_DATA_FOUND}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AdviserLeadsAccepted;
