import 'babel-polyfill';
import { fetch, fetchWithAuth, sendFileWithAuth } from "../../utility/request";
import {
  USERS_REGISTER_INTEREST,
  USER_ADD_ADVICE_AREA,
  USER_GET_ADVICE_AREA,
  USER_GET_NOTES,
  USER_GET_SINGLE_ADVICE_AREA,
  USER_LOGIN,
  USER_REGISTER,
  ADD_ADVICE_NOTES,
  UPDATE_ADVICE_NOTES,
  USER_GET_PROFILE,
  GET_PROFILE_USER,
  USERS_UPDATE_ACCOUNT,
  USERS_CHANGE_PASSWORD,
  CLOSE_ADVICE_NEED,
  RESEND_ACTIVATION_EMAIL,
  ADVISOR_REGISTER,
  FORGOT_PASSWORD,
  INVITE_USERS,
  SEARCH_MORTGAGE_NEEDS,
  ACCEPT_RJECT_BID,
  GET_ADVICE_AREA_BID,
  START_CHAT,
  SEND_MESSAGE,
  GET_ADVISOR_ACCEPTED_LEADS,
  GET_RECENT_MESSAGES,
  SEND_FILE_TO_CHAT,
  GET_ADVISOR_PROFILE,
  SET_RECENT_MESSAGES_OF_CHAT_TO_READ,
  ADVISOR_ADD_OFFER,
  ADVISOR_EDIT_OFFER,
  ADVISOR_DELETE_OFFER,
  GET_ADVISOR_LINKS,
  SET_ADVISOR_LINKS,
  ADVISOR_ADD_REVIEW,
  ADVISOR_UPDATE_ABOUT,
  ADVISOR_UPDATE_GENERAL_INFO,
  ADVISOR_UPDATE_NOTIFICATION_PREFERENCES,
  ADVISOR_GET_NOTIFICATION_PREFERENCES,
  ADVISOR_GET_PRODUCT_PREFERENCES,
  ADVISOR_UPDATE_PRODUCT_PREFERENCES,
  ADVISOR_UPDATE_CUSTOMER_PREFERENCES,
  ADVISOR_GET_CUSTOMER_PREFERENCES,
  ADVISOR_GET_FIRSTMESSAGE_PREFERENCES,
  ADVISOR_UPDATE_FIRSTMESSAGE_PREFERENCES,
  ADVISOR_UPDATE_LOCATION_PREFERENCES,
  ADVISOR_GET_LOCATION_PREFERENCES,
  ADVISOR_UPDATE_BILLING_ADDRESS,
  ADVISOR_GET_BILLING_ADDRESS,
  ADVISOR_UPDATE_TEAMS,
  ADVISOR_ADD_TEAMS,
  ADVISOR_GET_TEAMS,
  ADVISOR_DELETE_TEAMS,
  ADVISOR_MATCHED_LEADS,
  ADVISOR_SEARCH,
  ADVISOR_DASHBOARD_DATA,
  ADVISOR_MAKE_ENQUIRY,
  USER_ACCEPT_DECLINE_OFFER,
  ADVISOR_UPDATE_DEFAULT_PREFERENCES,
  ADVISOR_GET_DEFAULT_PREFERENCES,
  ADVISOR_STRIPE_GET_ALL_CARD,
  ADVISOR_STRIPE_DELETE_CARD,
  ADVISOR_STRIPE_SAVE_CARD,
  GET_RECENT_NOTIFICATIONS,
  READ_RECENT_NOTIFICATIONS,
  SEARCH_POST_CODE,
  SEARCH_LOCATION_AUTO_SUGGESTION,
  CONTENT_PAGES,
  FAQ_LIST,
  CONTACT_US,
  USER_ADVICE_GET_NOTES,
  REPORT_NEED_SPAM,
  REPORT_REVIEW_SPAM,
  MARK_AREA_READ,
  SERVICE_TYPE,
  GET_TEAM_MEMBER,
  UPDATE_COMPANY_ADMIN,
  GET_ALL_ADVISOR,
  SEARCH_ACCEPTED_NEEDS,SERVICE_TYPE_AUTH,
  MARK_PROJECT_COMPLETED,
  SERVICES_WITH_PRODUCT_PREFRENCES,
  INVOICE_DATA,
  SEEN_MESSAGES,
  GET_ADVISOR_PROFILE_BY_ID,
  GET_COUNT_FILTER,
  GET_COUNT_ACCEPTED_FILTER,
  GET_MORTGAGE_SIZE,
  DOWNLOAD_INVOICE_DATA,
  SEND_MAIL_USER_MATCHED_LEADS,
  ADD_CHATGPT_RESPONSE,
} from "../../Constants/apiEndPoints";

const returnErrors = (err: any) => {
  if (err.response && err.response.data) {
    if (err.response.data.details && err.response.data.details.body.length) {
      return { message: err.response.data.details.body[0].message };
    }
    return { message: err.response.data.message };
  }
  return err;
};

export const forgotPasswordRequest = async (data: object) => {
  try {
    return await fetch(FORGOT_PASSWORD, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const LoginRequest = async (data: object) => {
  try {
    return await fetch(USER_LOGIN, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const registerInterest = async (data: object) => {
  try {
    return await fetchWithAuth(USERS_REGISTER_INTEREST, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const registerUser = async (data: object) => {
  try {
    return await fetch(USER_REGISTER, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const addAdviceArea = async (data: object) => {
  try {
    return await fetchWithAuth(USER_ADD_ADVICE_AREA, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdviceArea = async () => {
  try {
    return await fetchWithAuth(USER_GET_ADVICE_AREA, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const getSingleAdviceArea = async (id: number) => {
  try {
    return await fetchWithAuth(`${USER_GET_SINGLE_ADVICE_AREA}/${id}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const getUserNotes = async (id: number) => {
  try {
    return await fetchWithAuth(`${USER_GET_NOTES}/${id}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const sendMailMatchedLeads = async (id: number) => {
  try {
    return await fetchWithAuth(`${SEND_MAIL_USER_MATCHED_LEADS}/${id}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const getUserAdviceNotes = async (id: number,advice_id: number) => {
  try {
    return await fetchWithAuth(`${USER_ADVICE_GET_NOTES}/${id}/${advice_id}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const addNotes = async (data: object) => {
  try {
    return await fetchWithAuth(ADD_ADVICE_NOTES, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};
export const getTeamMember = async (data: object) => {
  try {
    return await fetchWithAuth(GET_TEAM_MEMBER, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};
export const markAreaAsRead = async (data: object) => {
  try {
    return await fetchWithAuth(MARK_AREA_READ, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const UpdateNotes = async (data: object) => {
  try {
    return await fetchWithAuth(UPDATE_ADVICE_NOTES, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const getUserProfile = async () => {
  try {
    return await fetchWithAuth(USER_GET_PROFILE, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdviserUserProfile = async () => {
  try {
    return await fetchWithAuth(GET_PROFILE_USER, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateUserProfile = async (data: object) => {
  try {
    return await fetchWithAuth(USERS_UPDATE_ACCOUNT, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateUserPassword = async (data: object) => {
  try {
    return await fetchWithAuth(USERS_CHANGE_PASSWORD, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const closeAdviceNeed = async (data: object) => {
  try {
    return await fetchWithAuth(CLOSE_ADVICE_NEED, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const resendActivationEmail = async (data: object) => {
  try {
    return await fetchWithAuth(RESEND_ACTIVATION_EMAIL, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const AdvisorSignUpRequest = async (data: object) => {
  try {
    return await fetch(ADVISOR_REGISTER, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const inviteUsersRequest = async (data: object) => {
  try {
    return await fetchWithAuth(INVITE_USERS, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const advisorMatchedLeads = async (page: number,paramName:string,paramValue:string) => {
  try {
    if(paramName!='' && paramValue!=''){
      return await fetchWithAuth(`${ADVISOR_MATCHED_LEADS}?page=${page}&${paramName}=${paramValue}`, "GET");
    }else{
      return await fetchWithAuth(`${ADVISOR_MATCHED_LEADS}?page=${page}`, "GET");
    }
  } catch (err) {
    return returnErrors(err);
  }
};

export const getNeedDetails = async (data: object) => {
  try {
    return await fetchWithAuth(`getNeedDetails`, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const searchMortgageNeeds = async (data: object) => {
  try {
    return await fetchWithAuth(SEARCH_MORTGAGE_NEEDS, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const acceptRejectBid = async (data: object) => {
  try {
    return await fetchWithAuth(ACCEPT_RJECT_BID, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdviseAreaBid = async (id: number, status: number) => {
  try {
    return await fetchWithAuth(`${GET_ADVICE_AREA_BID}/${id}/${status}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const startChat = async (data: object) => {
  try {
    return await fetchWithAuth(START_CHAT, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const sendMessage = async (data: object) => {
  try {
    return await fetchWithAuth(SEND_MESSAGE, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorAcceptedLeads = async (paramName:string,paramValue:string,paramNameTime:string,paramValueTime:string,status:string,statusValue:any) => {
  try {
    if((paramName!='' && paramValue!='') && (paramNameTime!='' && paramValueTime!='')){
      return await fetchWithAuth(`${GET_ADVISOR_ACCEPTED_LEADS}?${paramName}=${paramValue}&${paramNameTime}=${paramValueTime}`, "GET");
    }else{
      return await fetchWithAuth(`${GET_ADVISOR_ACCEPTED_LEADS}?${status}=${statusValue}&${paramNameTime}=${paramValueTime}`, "GET");
    }
  } catch (err) {
    return returnErrors(err);
  }
};

export const getRecentMessages = async () => {
  try {
    return await fetchWithAuth(GET_RECENT_MESSAGES, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const getRecentNotification = async () => {
  try {
    return await fetchWithAuth(GET_RECENT_NOTIFICATIONS, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const readRecentNotifications = async () => {
  try {
    return await fetchWithAuth(READ_RECENT_NOTIFICATIONS, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};
export const sendFileToChat = async (data: object) => {
  try {
    return await sendFileWithAuth(SEND_FILE_TO_CHAT, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorProfile = async (id: number) => {
  try {
    return await fetchWithAuth(`${GET_ADVISOR_PROFILE}/${id}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorProfileById = async (id: number) => {
  try {
    return await fetch(`${GET_ADVISOR_PROFILE_BY_ID}/${id}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorDetails = async (slug: string) => {
  try {
    return await fetch(`getAdvisorDetails/${slug}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const getCompanyDetailsByAdvisor = async (slug: string) => {
  try {
    return await fetch(`getCompanyDetailsByAdvisor/${slug}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const setRecentMessagesOfChatToRead = async (data: object) => {
  try {
    return await fetchWithAuth(
      SET_RECENT_MESSAGES_OF_CHAT_TO_READ,
      "POST",
      data
    );
  } catch (err) {
    return returnErrors(err);
  }
};

export const setRecentMessagesOfAllChatToRead = async () => {
  try {
    return await fetchWithAuth("setRecentMessagesOfAllChatToRead", "POST", {});
  } catch (err) {
    return returnErrors(err);
  }
};

export const advisorAddOffer = async (data: object) => {
  try {
    return await fetchWithAuth(ADVISOR_ADD_OFFER, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const advisorDeleteOffer = async (id: number) => {
  try {
    return await fetchWithAuth(`${ADVISOR_DELETE_OFFER}/${id}`, "POST");
  } catch (err) {
    return returnErrors(err);
  }
};

export const advisorEditOffer = async (id: number, data: object) => {
  try {
    return await fetchWithAuth(`${ADVISOR_EDIT_OFFER}/${id}`, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorLinks = async () => {
  try {
    return await fetchWithAuth(GET_ADVISOR_LINKS, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const setAdvisorLinks = async (data: object) => {
  try {
    return await fetchWithAuth(SET_ADVISOR_LINKS, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const addReview = async (data: object) => {
  try {
    return await fetchWithAuth(ADVISOR_ADD_REVIEW, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const ReportNeedSpam = async (data: object) => {
  try {
    return await fetchWithAuth(REPORT_NEED_SPAM, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const ReportReviewSpam = async (data: object) => {
  try {
    return await fetchWithAuth(REPORT_REVIEW_SPAM, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const openAddReview = async (data: object) => {
  try {
    return await fetchWithAuth(`openAddReview`, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const getInvoice = async (id: number, data: object) => {
  try {
    return await fetchWithAuth(`invoice/${id}`, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const getInvoiceData = async (data: object) => {
  try {
    return await fetchWithAuth(INVOICE_DATA, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const downloadInvoice = async (data: object) => {
  try {
    return await fetchWithAuth(DOWNLOAD_INVOICE_DATA, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};
export const checkoutFromSavedCard = async (data: object) => {
  try {
    return await fetchWithAuth(`checkoutFromSavedCard`, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateAdvisorAboutUs = async (data: object) => {
  try {
    return await fetchWithAuth(ADVISOR_UPDATE_ABOUT, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateAdvisorGeneralInfo = async (data: object) => {
  try {
    return await sendFileWithAuth(ADVISOR_UPDATE_GENERAL_INFO, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const getNotificationPreferences = async () => {
  try {
    return await fetchWithAuth(ADVISOR_GET_NOTIFICATION_PREFERENCES, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const getProductPreferencesWithServices = async () => {
  try {
    return await fetchWithAuth(SERVICES_WITH_PRODUCT_PREFRENCES, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateNotificationPreferences = async (data: object) => {
  try {
    return await fetchWithAuth(
      ADVISOR_UPDATE_NOTIFICATION_PREFERENCES,
      "POST",
      data
    );
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorProductPreference = async () => {
  try {
    return await fetchWithAuth(ADVISOR_GET_PRODUCT_PREFERENCES, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateAdvisorProductPreference = async (data: object) => {
  try {
    return await fetchWithAuth(
      ADVISOR_UPDATE_PRODUCT_PREFERENCES,
      "POST",
      data
    );
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorCustomerPreference = async () => {
  try {
    return await fetchWithAuth(ADVISOR_GET_CUSTOMER_PREFERENCES, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateAdvisorCustomerPreference = async (data: object) => {
  try {
    return await fetchWithAuth(
      ADVISOR_UPDATE_CUSTOMER_PREFERENCES,
      "POST",
      data
    );
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorDefaultPreference = async () => {
  try {
    return await fetchWithAuth(ADVISOR_GET_DEFAULT_PREFERENCES, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateAdvisorDefaultPreference = async (data: object) => {
  try {
    return await fetchWithAuth(
      ADVISOR_UPDATE_DEFAULT_PREFERENCES,
      "POST",
      data
    );
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorLocationPreference = async () => {
  try {
    return await fetchWithAuth(ADVISOR_GET_LOCATION_PREFERENCES, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateAdvisorLocationPreference = async (data: object) => {
  try {
    return await fetchWithAuth(
      ADVISOR_UPDATE_LOCATION_PREFERENCES,
      "POST",
      data
    );
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorFirstMessage = async () => {
  try {
    return await fetchWithAuth(ADVISOR_GET_FIRSTMESSAGE_PREFERENCES, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateFirstMessage = async (data: object) => {
  try {
    return await fetchWithAuth(
      ADVISOR_UPDATE_FIRSTMESSAGE_PREFERENCES,
      "POST",
      data
    );
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorBillingAddress = async () => {
  try {
    return await fetchWithAuth(ADVISOR_GET_BILLING_ADDRESS, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateAdvisorBillingAddress = async (data: object) => {
  try {
    return await fetchWithAuth(ADVISOR_UPDATE_BILLING_ADDRESS, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorTeam = async (companyId: number) => {
  try {
    return await fetchWithAuth(`${ADVISOR_GET_TEAMS}/${companyId}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const checkIfExistInAdvisorTeam = async (companyId: number) => {
  try {
    return await fetchWithAuth(`checkIfExistInAdvisorTeam/${companyId}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const addAdvisorTeam = async (data: object) => {
  try {
    return await fetchWithAuth(ADVISOR_ADD_TEAMS, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateAdvisorTeam = async (data: object) => {
  try {
    return await fetchWithAuth(ADVISOR_UPDATE_TEAMS, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const updateCompanyAdmin = async (data: object) => {
  try {
    return await fetchWithAuth(UPDATE_COMPANY_ADMIN, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const deleteAdvisorTeam = async (id: number) => {
  try {
    return await fetchWithAuth(`${ADVISOR_DELETE_TEAMS}/${id}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const searchAdvisorsViaFilters = async (data: object) => {
  try {
    return await fetchWithAuth(ADVISOR_SEARCH, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const getAdvisorDashboardData = async () => {
  try {
    return await fetchWithAuth(ADVISOR_DASHBOARD_DATA, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const makeEnquiry = async (data: object) => {
  try {
    return await fetchWithAuth(ADVISOR_MAKE_ENQUIRY, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const selectOrDeclineOffer = async (bidId: number, status: number) => {
  try {
    return await fetchWithAuth(
      `${USER_ACCEPT_DECLINE_OFFER}/${bidId}/${status}`,
      "GET"
    );
  } catch (err) {
    return returnErrors(err);
  }
};

export const stripeDeleteCard = async (data: object) => {
  try {
    return await fetchWithAuth(ADVISOR_STRIPE_DELETE_CARD, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const stripeSaveCard = async (data: object) => {
  try {
    return await fetchWithAuth(ADVISOR_STRIPE_SAVE_CARD, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const stripeGetAllCards = async () => {
  try {
    return await fetchWithAuth(ADVISOR_STRIPE_GET_ALL_CARD, "POST", {});
  } catch (err) {
    return returnErrors(err);
  }
};

export const searchPostalCode = async (data: object) => {
  try {
    return await fetch(SEARCH_POST_CODE, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};


export const searchLocationPostal = async (data: object) => {
  try {
    return await fetch(SEARCH_LOCATION_AUTO_SUGGESTION, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const ContentPages = async (data: object) => {
  try {
    return await fetch(CONTENT_PAGES, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const FaqData = async (data: any) => {
  try {
    var dataType = "customer";
    if(data.audience==1){
      dataType="adviser";
    }
    return await fetch(FAQ_LIST+"?audience="+dataType, "GET", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const contactUs = async (data: object) => {
  try {
    return await fetchWithAuth(CONTACT_US, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};
export const getServiceType = async () => {
  try {
    return await fetchWithAuth(SERVICE_TYPE, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};
export const getAllAdvisor = async () => {
  try {
    return await fetchWithAuth(GET_ALL_ADVISOR, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};
export const getServiceTypeWithAuth = async () => {
  try {
    return await fetchWithAuth(SERVICE_TYPE_AUTH, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};
export const searchAcceptedLeads = async (data: object) => {
  try {
    return await fetchWithAuth(SEARCH_ACCEPTED_NEEDS, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};
export const markProjectCompleted = async (data: object) => {
  try {
    return await fetchWithAuth(MARK_PROJECT_COMPLETED, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};
export const seenMessages = async (data: object) => {
  try {
    return await fetchWithAuth(SEEN_MESSAGES, "POST", data);
  } catch (err) {
    return returnErrors(err);
  }
};

export const getCountFilter = async () => {
  try {
    return await fetchWithAuth(GET_COUNT_FILTER, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};
export const getCountAcceptedFilter = async (duration:string,durationValue:any) => {
  try {
    return await fetchWithAuth(`${GET_COUNT_ACCEPTED_FILTER}?${duration}=${durationValue}`, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};
export const getMortgageSize = async () => {
  try {
    return await fetchWithAuth(GET_MORTGAGE_SIZE, "GET");
  } catch (err) {
    return returnErrors(err);
  }
};

export const saveChatgptResponse = async (currentQuestion:any,user_id:any) => {
  try {
    return await fetchWithAuth(ADD_CHATGPT_RESPONSE, "POST", {'question':currentQuestion,'user_id':user_id});
  } catch (err) {
    return returnErrors(err);
  }
};