import { usePubNub } from "pubnub-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { capitalize } from "../../../utility/commonUtils";
import useLoggedInUserData from "../../../utility/useLoggedInUserData";

import {
  getAdvisorFirstMessage,
  sendFileToChat,
  sendMessage,
  setRecentMessagesOfChatToRead,
} from "../service";

import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { Twemoji } from "react-emoji-render";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

// RCE CSS
import "react-chat-elements/dist/main.css";
//MessageBox component
import { MessageBox, SystemMessage } from "react-chat-elements";

import { downloadAUrl } from "better-file-downloads";
import { useScrollToBottom } from "use-scroll-to-bottom";
import $ from "jquery";
import useDebounce from "../../../hooks/useDebounce";
import { ADVISOR_DEFAULT_IMAGE } from "../../../Constants";
import moment from "moment";

interface SendMessageModalProps {
  from_user_id: string;
  to_user_id: string;
  channel: { channel_id: number; channel_name: string };
  data: MessageProps[] | any;
  bid?: any;
}

interface MessageProps {
  id: string;
  text: string;
  from_user_id: string;
  to_user_id: string;
  channel_id?: number;
  status?: number;
  created_at?: any;
  message_type?: number;
  attachment?: string;
  to_user_id_seen?: number;
}

let scrollToDivTimer: any;

const SendMessageModal = (props: SendMessageModalProps | any) => {
  const user = useLoggedInUserData();
  const [setBottomRef, isBottom] = useScrollToBottom();
  const backendStorageUrl = process.env.REACT_APP_IMAGE_URL || "";
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [typingIndicator, setTypingIndicator] = useState<string>("");
  const [recieverOnlineIndicator, setRecieverOnlineIndicator] =
    useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const [emojiPickerState, setEmojiPickerState] = useState(false);
  let emojiPicker;
  if (emojiPickerState) {
    emojiPicker = (
      <Picker
        set="twitter"
        style={{ width: "327px" }}
        title="Pick your emoji…"
        emoji="point_up"
        showSkinTones={true}
        onSelect={(emoji: any) => {
          const useEmoji = emoji.native; //emoji.colons
          setMessageText(messageText + useEmoji + " ");
        }}
      />
    );
  }
  const triggerEmojiPicker = (event: any) => {
    event.preventDefault();
    setFilePickerState(false);
    setEmojiPickerState(!emojiPickerState);
  };

  // Dropzone
  const [isDropzoneDisabled, setIsDropzoneDisabled] = useState(false);
  const triggerFilePicker = (event: any) => {
    event.preventDefault();
    setEmojiPickerState(false);
    setFilePickerState(!filePickerState);
  };
  // specify upload params and url for your files
  const getUploadParams = ({ file, meta }: any) => {
    const url = "https://httpbin.org/post";
    return {
      url,
      meta: { fileUrl: `${url}/${encodeURIComponent(meta.name)}` },
    };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }: any, status: any) => {
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files: any, allFiles: any) => {
    setIsDropzoneDisabled(true);
    const formData = new FormData();
    formData.append("from_user_id", user.id);
    formData.append("to_user_id", props.to_user_id);
    formData.append("channel_id", props.channel.channel_id);
    formData.append("to_user_id_seen", recieverOnlineIndicator ? "1" : "0");
    formData.append("image", files[0].file);
    const res = await sendFileToChat(formData);
    if (res.status && res.data) {
      const postedData = res.data;
      // After api success send message to pubnub server
      pubnub
        .publish({
          channel: channels[0],
          message: {
            id: res.data.id,
            created_at: res.data.created_at,
            text: messageText,
            from_user_id: postedData.from_user_id,
            to_user_id: postedData.to_user_id,
            message_type: postedData.message_type,
            attachment: postedData.attachment,
            to_user_id_seen: recieverOnlineIndicator
              ? 1
              : postedData?.to_user_id_seen || 0,
          },
        })
        .then(() => {
          setIsDropzoneDisabled(false);
          setFilePickerState(false);
          allFiles.forEach((f: any) => f.remove());
        });
    }
  };

  const [filePickerState, setFilePickerState] = useState(false);
  let filePicker;
  if (filePickerState) {
    filePicker = (
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        // accept="image/*,audio/*,video/*,"
        maxFiles={1}
        multiple={false}
        submitButtonDisabled={isDropzoneDisabled}
        submitButtonContent="Send"
        // canCancel={false}
        inputContent={(files, extra) =>
          extra.reject
            ? "Image, audio and video files only"
            : "Choose a file or drag it here"
        }
        styles={{
          dropzone: {
            width: "327px",
            height: "auto",
            overflow: "auto",
            flexDirection: "row",
          },
          dropzoneActive: { borderColor: "green" },
          dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
          inputLabel: (files, extra) => (extra.reject ? { color: "red" } : {}),
        }}
      />
    );
  }

  const pubnub = usePubNub();
  const [channels, setChannels] = useState([props.channel.channel_name]);
  const [messages, setMessages] = useState<MessageProps[]>(props.data || []);
  const [messageText, setMessageText] = useState<string>("");
  const debounced = useDebounce(messageText, 5000);

  const handleSendMessage = async (messageText: string) => {
    if (messageText !== "") {
      setEmojiPickerState(false);
      setFilePickerState(false);
      messageText = messageText.trim();
      // API call here
      const postedData = {
        from_user_id: user.id,
        to_user_id: props.to_user_id,
        channel_id: props.channel.channel_id,
        text: messageText,
        message_type: 0,
        to_user_id_seen: recieverOnlineIndicator ? 1 : 0,
      };
      const res = await sendMessage(postedData);
      if (res.data) {
        // After api success send message to pubnub server
        pubnub
          .publish({
            channel: channels[0],
            message: {
              id: res.data.id,
              created_at: res.data.created_at,
              text: messageText,
              from_user_id: postedData.from_user_id,
              to_user_id: postedData.to_user_id,
              message_type: postedData.message_type,
              to_user_id_seen: recieverOnlineIndicator
                ? 1
                : postedData?.to_user_id_seen || 0,
            },
          })
          .then(() => {
            setMessageText("");
          });
      }
    }
  };

  const handleMessage = useCallback((event: any) => {
    const message = event.message;
    if (typeof message === "string" || message.hasOwnProperty("text")) {
      const messageData = {
        id: message.id,
        text: message.text || message,
        from_user_id: message.from_user_id,
        to_user_id: message.to_user_id,
        created_at: message.created_at,
        message_type: message.message_type,
        attachment: message.attachment,
        to_user_id_seen: message?.to_user_id_seen || 0,
      };
      setMessages((messages: MessageProps[]) => [...messages, messageData]);
      if (message.from_user_id === user.id) {
        scrollToBottom();
      } else if (message.from_user_id !== user.id) {
        const element = $("#messages-data-id")[0];
        const calc = element.scrollHeight - element.scrollTop;
        const clientHeight = element.clientHeight;
        if (calc - 150 >= clientHeight) {
          setNotificationCount((prevCount) => ++prevCount);
        } else {
          scrollToBottom();
        }
      }
    }
  }, []);

  const setRecentMessagesOfChatToReadCall = () => {
    if (notificationCount > 0) {
      setNotificationCount(0);
      //Call api for set messsage seen
      setRecentMessagesOfChatToRead({ channel_id: props.channel.channel_id });
    }
  };

  const handleSignal = useCallback((e: any) => {
    if (e.message.from.id !== user.id) {
      const isSignal = e.message;
      if ("precence" in isSignal) {
        setRecieverOnlineIndicator(isSignal.precence ? true : false);
      } else {
        setTypingIndicator(
          isSignal.typing ? `${e.message.from.name} is typing...` : ""
        );
        setRecieverOnlineIndicator(isSignal.typing ? true : true);
      }
    }
  }, []);

  const sendIndicatorEvent = (signalData: Object) => {
    pubnub.signal({
      message: {
        from: { id: user.id, name: user.name },
        ...signalData,
      },
      channel: channels[0],
    });
  };

  useEffect(() => {
    if (isBottom) {
      setRecentMessagesOfChatToReadCall();
    }
  }, [isBottom]);

  useEffect(() => {
    pubnub.addListener({ message: handleMessage, signal: handleSignal });
    return () => {
      pubnub.removeListener({ message: handleMessage, signal: handleSignal });
    };
  }, [pubnub]);

  useEffect(() => {
    if (channels.length && channels[0]) {
      const typingData =
        messageText === "" || messageText === debounced ? false : true;
      sendIndicatorEvent({ typing: typingData });
    }
  }, [messageText, debounced]);

  useEffect(() => {
    if (channels.length && channels[0]) {
      pubnub.subscribe({ channels });
      sendIndicatorEvent({ precence: true });
    }
  }, [channels]);

  useEffect(() => {
    if (props.channel) {
      setChannels([props.channel.channel_name]);
    }
  }, [props.channel]);

  useEffect(() => {
    setMessages(() => props.data);
    // $("#messages-data-id").scrollTop($("#messages-data-id")[0].scrollHeight);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    scrollToDivTimer = setTimeout(() => {
      // scrollToBottom();
      $("#messages-data-id").scrollTop($("#messages-data-id")[0].scrollHeight);
      if (messagesEndRef.current && messagesEndRef.current !== null) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
    // return () => {
    //   clearTimeout(scrollToDivTimer);
    // };
  }, [props.data]);

  const receiverName = props?.bid
    ? capitalize(props?.bid?.display_name || props?.bid?.name)
    : null;
  const receiverSubName = props?.bid
    ? capitalize(props?.bid?.company_name)
    : null;

  const downloadFile = async (url: any, fileName: any, extension: any) => {
    await downloadAUrl(url, {
      fileName: fileName,
      extension: `.${extension}`,
      contentType: `application/${extension}`,
    });
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current && messagesEndRef.current !== null) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      setRecentMessagesOfChatToReadCall();
    }
  };

  const [firstMessageState, setFirstMessageState] = useState<boolean>(false);
  const [firstMessage, setFirstMessage] = useState<string>("");
  const getAdvisorFirstMessageCall = async () => {
    const res = await getAdvisorFirstMessage();
    if (res.data && res.data.first_message) {
      setFirstMessage(res.data.first_message);
      setMessageText(res.data.first_message);
    }
  };

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    const { checked } = event.target;
    if (checked) {
      setFirstMessageState(true);
      if (firstMessage === "") {
        getAdvisorFirstMessageCall();
      } else {
        setMessageText(firstMessage);
      }
    } else {
      setFirstMessageState(false);
      setMessageText("");
    }
  };

  const messagesWithDates: any = messages.reduce((result: any, record: any) => {
    const cDateOnly = record.created_at
      ? record.created_at.slice(0, record.created_at.indexOf("T"))
      : "";
    if (!result[cDateOnly]) result[cDateOnly] = [];
    result[cDateOnly].push(record);

    return result;
  }, {});

  return (
    <>
      <div
        className="modal fade popup-modal"
        id="message_data"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="message_dataTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body text-center message-content">
              <div className="row small-content justify-content-center align-items-center pb-3 mb-3">
                {/* <div className="col-12 text-left back-btn">
                  <a
                    href="javascript:void(0)"
                    className="back-btn text-black"
                    data-dismiss="modal"
                    aria-label="Back"
                    onClick={() => sendIndicatorEvent({ precence: false })}
                  >
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                    Back
                  </a>
                </div> */}
                <div className="col-12 main-title text-center jscontent-left">
                  <img
                    src={ADVISOR_DEFAULT_IMAGE}
                    className="img-fluid icon-testi d-inline-block"
                    alt=""
                  />
                  <h4 className="d-inline-block text-left ml-4">
                    {receiverName}
                    <p className="mb-0">{receiverSubName}</p>
                  </h4>
                </div>
                <div className="col-12 close-btn">
                  <a
                    href="javascript:void(0)"
                    className="close-btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => sendIndicatorEvent({ precence: false })}
                  >
                    Close
                  </a>
                </div>
              </div>
              <div
                id="messages-data-id"
                className="row messages-data text-left mb-3"
              >
                {Object.keys(messagesWithDates).map((key, index) => {
                  return (
                    <>
                      <SystemMessage text={moment(key).format("DD MMM YYYY")} />
                      {messagesWithDates[key].map(
                        (message: any, index: number) => {
                          const messagePosition =
                            message.from_user_id === user.id ? "right" : "left";
                          const meesageStatus =
                            messagePosition === "right"
                              ? message.to_user_id_seen
                                ? "read"
                                : "sent"
                              : "";

                          const fileExtension = message.attachment
                            ? message.attachment.split(/\.(?=[^\.]+$)/)
                            : "";
                          const fileSize = capitalize(fileExtension[1] || "");

                          return (
                            <>
                              <div
                                style={{ width: "100%" }}
                                key={`message-${message.id}-${message?.created_at}`}
                              >
                                <p>
                                  {message.message_type === 0 ? (
                                    <MessageBox
                                      position={messagePosition}
                                      type={"text"}
                                      text={
                                        <Twemoji
                                          svg
                                          text={message.text}
                                          className="emoji-custom"
                                        />
                                      }
                                      status={meesageStatus}
                                      date={new Date(message?.created_at)}
                                    />
                                  ) : (
                                    <MessageBox
                                      position={messagePosition}
                                      type={
                                        message.message_type === 1
                                          ? "photo"
                                          : "file"
                                      }
                                      text={
                                        message.message_type === 1
                                          ? ""
                                          : message.attachment
                                      }
                                      status={meesageStatus}
                                      date={new Date(message?.created_at)}
                                      data={{
                                        uri:
                                          backendStorageUrl +"chat/"+
                                          message.attachment,
                                        status: {
                                          click: true,
                                          download: true,
                                        },
                                        size: fileSize,
                                      }}
                                      onOpen={() => {
                                        downloadFile(
                                          `${
                                            backendStorageUrl + fileExtension[0]
                                          }`,
                                          message.attachment,
                                          fileExtension[1]
                                        );
                                      }}
                                    />
                                  )}
                                </p>
                              </div>
                            </>
                          );
                        }
                      )}
                    </>
                  );
                })}
                <div ref={setBottomRef}></div>
                <div
                  style={{ float: "left", clear: "both" }}
                  ref={messagesEndRef}
                ></div>
              </div>

              <div className="text-left">
                <div className="form-row">
                  <div className="form-group check-data col-lg-12 pl-lg-4 pl-0 mb-0">
                    {typingIndicator !== "" ? (
                      <div className="typing-container">
                        <p>{typingIndicator}</p>
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-6 col-md-6">
                        <label htmlFor="cust_name" className="p-0 mb-2">
                          Send a Message
                        </label>
                      </div>
                      <div className="col-6 col-md-4">
                        {user.user_role === 1 && messages.length === 0 ? (
                          <div className="form-group checkboxes-data mb-0  text-right">
                            <input
                              type="checkbox"
                              id="first_message"
                              name="first_message"
                              onChange={handleChangeCheckbox}
                              checked={firstMessageState}
                            />
                            <label htmlFor="first_message">First message</label>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {notificationCount ? (
                    <div className="notify-container">
                      <span className="notify-link" onClick={scrollToBottom}>
                        <i
                          className="fa fa-chevron-down"
                          aria-hidden="true"
                        ></i>{" "}
                        <sup>{notificationCount}</sup>
                      </span>
                    </div>
                  ) : null}
                  <div className="emoji-container">{emojiPicker}</div>
                  <div className="emoji-container file-container">
                    {filePicker}
                  </div>
                  <div className="form-group check-data col-lg-10 pl-lg-4 pl-0 mb-0">
                  {user.user_role === 0 ? (<textarea
                      className="mb-md-0"
                      name="message_send"
                      id="message_send"
                      cols={30}
                      rows={10}
                      placeholder="Message the financial adviser e g ask about completion times, question about the mortgage, whether home visits are on option, etc."
                      value={messageText}
                      onChange={(e) => setMessageText(e.target.value)}
                      // onKeyPress={(e) => {
                      //   if (e.key !== "Enter") return;
                      //   handleSendMessage(messageText);
                      // }}
                    ></textarea>
                    ) : <textarea
                    className="mb-md-0"
                    name="message_send"
                    id="message_send"
                    cols={30}
                    rows={10}
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                    // onKeyPress={(e) => {
                    //   if (e.key !== "Enter") return;
                    //   handleSendMessage(messageText);
                    // }}
                  ></textarea>}
                  </div>
                  <div className="form-group col-lg-2 text-center mb-0">
                    
                    <div className="emoji-files-buttons">
                      <a
                        href="javascript:void(0)"
                        className="m-r-10"
                        onClick={triggerEmojiPicker}
                      >
                        <i
                          className="fa fa-smile-o font-weight-200 font-size-22"
                          aria-hidden="true"
                        ></i>
                      </a>
                      <a href="javascript:void(0)" onClick={triggerFilePicker}>
                        <i
                          className="fa fa-paperclip font-weight-200 font-size-22"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                    <div className="send-message-buttons">
                      <button
                        type="button"
                        className="theme-btn text-uppercase mr-md-3 mr-0"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSendMessage(messageText);
                        }}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SendMessageModal);
